import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HallsState } from '@/store/halls/types'
import { parseLS } from '@/utils'

const initialHallsState: HallsState = {
  hallsList: parseLS('hallsList', []),
  currentHall: parseLS('currentHall', 0),
  addresses: parseLS('addresses', []),
}

const hallsSlice = createSlice({
  name: 'halls',
  initialState: initialHallsState,
  reducers: {
    setHallsList: (state, action: PayloadAction<number[]>) => {
      state.hallsList = action.payload
    },
    setCurrentHall: (state, action: PayloadAction<number>) => {
      state.currentHall = action.payload
    },
    setAddresses: (
      state,
      action: PayloadAction<{ hall: number; gmt: number; region: string }[]>
    ) => {
      state.addresses = action.payload
    },
  },
})

export const { setHallsList, setCurrentHall, setAddresses } = hallsSlice.actions
export const { reducer: hallsReducer } = hallsSlice
