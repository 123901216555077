import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { BasketResponse } from '@/containers/Order/types'
import { OrderState } from '@/store/order/types'

const initialOrderState: OrderState = {
  basket: {
    total: '0',
    count: 0,
    products: [
      {
        id: 0,
        name: '0',
        quantity: 0,
        price: '0',
        total: '0',
      },
    ],
  },
}

// todo: remove store probably
const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    setBasket: (state, action: PayloadAction<BasketResponse>) => {
      state.basket = action.payload
    },
  },
})

export const { setBasket } = orderSlice.actions

export const { reducer: orderReducer } = orderSlice
