// shifts and GMT
export const DEFAULT_SHIFT_START = 7
export const DEFAULT_SHIFT_END = 20
export const MOSCOW_GMT = 3
export const KZ_GMT = 5
export const GMT_DIFF = KZ_GMT - MOSCOW_GMT
export const SHIFT_LIMIT_MIN = 0
export const SHIFT_LIMIT_MAX = 23
export const HOURS_PER_DAY = 24

export const shiftMoscowGmtFirstSingle = (
  gmt: number,
  defaultEnd?: boolean,
  defaultShift: number = DEFAULT_SHIFT_START
) => {
  const shift = defaultEnd ? DEFAULT_SHIFT_END : defaultShift
  return gmt === MOSCOW_GMT ? shift : gmt - MOSCOW_GMT + shift
}

// regions
export const locationsByGmt = <T extends { gmt: number }>(location: T): T & { region: string } => {
  switch (location.gmt) {
    case 5:
      return {
        ...location,
        region: `Астана`,
      }
    default:
      return {
        ...location,
        region: `Не найден`,
      }
  }
}

// filters
export const DASHBOARD_FILTER = 'dashboardFilter'
export const ORDER_FILTER = 'orderFilter'
export const CASHBOX_FILTER = 'cashboxFilter'

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group: string) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    )

export const textConstants = {
  filterHeader: `Фильтры`,
}

export const formatNumberWithSpaces = (x: number | string): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export enum MediaBreakpoints {
  Mobile = '(min-width: 320px) and (max-width: 1129px)',
  DesktopSmall = '(min-width: 1130px) and (max-width: 1336px)',
  DesktopMedium = '(min-width: 1337px) and (max-width: 1440px)',
  DesktopLarge = '(min-width: 1441px) and (max-width: 1920px)',
}

// regex
export const REGEX = {
  PHONE: /^(\+?)(7)(\s?)([(]?7[0124567][0-8][)]?)([\s|-]?)(\d{3})([\s|-]?)(\d{2})([\s|-]?)(\d{2})$/,
  OLD_PHONE:
    /^(\+?)(7)(\s?)([(]?7[0124567][0-8][)]?)([\s|-]?)(\d{3})([\s|-]?)(\d{2})([\s|-]?)(\d*)$/,
  FULLNAME: /^[а-яА-Яa-zA-ZЁё` -]+/,
}
