import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  PhoneCreateForm,
  PhoneFormModalStatus,
  PhoneModalVisibility,
  PhoneRequestItemStatus,
} from '@/containers/ChangePhone/types'

import { PhoneState } from './types'

const initialPhoneState: PhoneState = {
  modalVisibility: {
    form: false,
    status: false,
    warning: false,
  },
  formModalData: {
    old_phone: '',
    new_phone: '',
    reason: '',
    employee_phone: '',
    employee_name: '',
  },
  statusModalStatus: 'new',
  requestStatus: 'new',
  formModalStatus: 'create',
  canUserEdit: false,
  requestId: 0,
}

const phoneSlice = createSlice({
  name: 'phone',
  initialState: initialPhoneState,
  reducers: {
    setModalVisibility: (
      state: PhoneState,
      action: PayloadAction<Partial<PhoneModalVisibility>>
    ) => {
      state.modalVisibility = { ...state.modalVisibility, ...action.payload }
    },
    setFormModalData: (state, action: PayloadAction<PhoneCreateForm>) => {
      state.formModalData = { ...state.formModalData, ...action.payload }
    },
    setFormModalStatus: (state, action: PayloadAction<PhoneFormModalStatus>) => {
      state.formModalStatus = action.payload
    },
    setRequestId: (state, action: PayloadAction<number>) => {
      state.requestId = action.payload
    },
    setCanUserEdit: (state, action: PayloadAction<boolean>) => {
      state.canUserEdit = action.payload
    },
    setRequestStatus: (state, action: PayloadAction<PhoneRequestItemStatus>) => {
      state.requestStatus = action.payload
    },
  },
})

export const {
  setModalVisibility,
  setFormModalData,
  setFormModalStatus,
  setRequestId,
  setCanUserEdit,
  setRequestStatus,
} = phoneSlice.actions

export const { reducer: phoneReducer } = phoneSlice
