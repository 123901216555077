import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserState } from '@/store/auth/types'
import { clearLS, parseLS } from '@/utils'

const initialUserState: UserState = {
  ...parseLS('user', {}),
  isAdmin: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialUserState,
  reducers: {
    loginUser: (state, action: PayloadAction<UserState>) => ({
      ...state,
      ...action.payload,
    }),
    // todo: не понятно, как менять стейт целиком
    logoutUser: () => {
      clearLS()
      document.cookie = 'bbp-cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      return {
        ...initialUserState,
      }
    },
  },
})

export const { loginUser, logoutUser } = authSlice.actions
export const { reducer: authReducer } = authSlice
