import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ScreenState, SmsState } from '@/store/sms/types'

const initialSmsState: SmsState = {
  activeSteps: [1],
  screen: ScreenState.CARD_LIST,
}

const smsSlice = createSlice({
  name: 'sms',
  initialState: initialSmsState,
  reducers: {
    addActiveStep: (state, action: PayloadAction<number[]>) => {
      state.activeSteps = action.payload
    },
    resetSteps: state => {
      state.activeSteps = initialSmsState.activeSteps
    },
    setScreen: (state, action: PayloadAction<ScreenState>) => {
      state.screen = action.payload
    },
  },
})

// actions
export const { addActiveStep, resetSteps, setScreen } = smsSlice.actions

// reducer
export const { reducer: smsReducer } = smsSlice
