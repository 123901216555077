import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MenuState } from '@/store/menu/types'
import { parseLS } from '@/utils'

const initialMenuState: MenuState = {
  ...parseLS('menu', { main: [], header: [] }),
}

const menuSlice = createSlice({
  name: 'menu',
  initialState: initialMenuState,
  reducers: {
    setMenu: (state, action: PayloadAction<MenuState>) => {
      state.header = action.payload.header
      state.main = action.payload.main
    },
  },
})

export const { setMenu } = menuSlice.actions

export const { reducer: menuReducer } = menuSlice
